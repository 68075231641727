import request from '../request'

//动态皮肤列表
export const ShopSkinDtIndexRequest = p => {
    return request({
        method:'GET',
        url:'ShopSkinDt/index',
        params: p
    })
}

//动态皮肤序列
export const ShopSkinDtListRequest = () => {
    return request({
        method:'GET',
        url:'ShopSkinDt/list'
    })
}

//添加动态皮肤
export const ShopSkinDtAddRequest = d => {
    return request({
        method:'POST',
        url:'ShopSkinDt/add',
        data: d
    })
}

//删除动态皮肤
export const ShopSkinDtDelRequest = id => {
    return request({
        method:'DELETE',
        url:'ShopSkinDt/del',
        data: {
            id: id
        }
    })
}

//修改动态皮肤
export const ShopSkinDtEditRequest = data => {
    return request({
        method:'PUT',
        url:'ShopSkinDt/edit',
        data
    })
}

//获取动态皮肤详情
export const ShopSkinDtDetailsRequest = id => {
    return request({
        method:'GET',
        url:'ShopSkinDt/details',
        params: {
            id
        }
    })
}